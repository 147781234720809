import React from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import './background-image.scss'

interface Props {
  className: string
  fileName: string
  height: string
  alt: string
}

// duotone: #15774a | rgb(21,119,74) | hab(152,82,47)
const BackgroundImage = ({ className, fileName, alt, ...other }: Props) => {
  const classes = ['background-image', className]
  const { backgroundImages } = useStaticQuery(
    graphql`
      query {
        backgroundImages: allFile(
          filter: { sourceInstanceName: { eq: "backgrounds" }, extension: { regex: "/png|jpg/" } }
          limit: 9999
        ) {
          nodes {
            relativePath
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                transformOptions: {
                  duotone: { highlight: "#15774a", shadow: "#000000", opacity: 80 }
                }
              )
            }
          }
        }
      }
    `
  )

  return (
    <div className={classNames(classes)}>
      <GatsbyImage
        image={getImage(backgroundImages.nodes.find((n) => n.relativePath == fileName))}
        alt={alt || ''}
        style={{ overflow: 'initial', position: 'initial' }}
        {...other}
      />
    </div>
  )
}

export default BackgroundImage
